@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/general.less";
@import "../../../less/_mixins/general.less";
@import (reference) "../../../../../node_modules/@is24/cosma-ui-icons/build/font/s24-icons.less";
@import "../../../less/_variables/color-palette.less";

// --- Status Messages ---
// notifications for error messages, alerts, information

@statusMessageShortIconWidth: 60px;
@statusMessageShortIconSize: 2rem;

// --- Message Frame

// Regular Message
.status-message {
	position: relative;
	border: 1px solid transparent;
	border-left-width: 8px;
	border-radius: 3px;
	padding: 16px 16px 16px 50px;
	line-height: 20px;
	background: @white;

	&::before {
		left: 17px;
		position: absolute;
		font-size: 24px;
		font-family: "IS24Icons";
		display: inline;
		padding-right: 10px;
		line-height: 30px;

		@media @mediaQueryPalmOnly {
			line-height: 28px;
		}
	}

	// Please do not use h1-h6 tags.
	// This is just as a fallback for the old status messages
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.status-title {
		font-size: 24px;
		line-height: 30px;
		font-weight: 300;
		display: inline-block;

		@media @mediaQueryPalmOnly {
			font-size: 22px;
			line-height: 28px;
			margin-bottom: 5px;
		}
	}

	.closer {
		font-size: 15px;
		border: none;
		background: none;
		position: absolute;
		right: 3px;
		top: 2px;
		display: inline;
		padding: 5px;
		cursor: pointer;

		&::before {
			content: "\e918";
			display: inline;
			font-family: "IS24Icons";
		}
	}

	// --- Icons
	&.status-confirm {
		border-color: @green500;
		&::before {
			color: @green500;
			content: "\e901";
		}
	}

	&.status-info,
	&.status-warning {
		border-color: @blue500;
		&::before {
			color: @blue500;
			content: "\e92b";
		}
	}

	&.status-error {
		border-color: @red500;
		&::before {
			color: @red500;
			content: "\e913";
		}
	}

	// --- Styles
	&.status-message--toast {
		background: @white;
		border-top-color: @gray400;
		border-right-color: @gray400;
		border-bottom-color: @gray400;
		border-left-width: 4px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

		.status-title {
			font-size: 18px;
			font-weight: 600;
			line-height: 25px;
		}

		&::before {
			line-height: 25px;
		}
	}

	&.status-message--minimal {
		border: none;
		background: none;
		padding: 0;
		display: inline-flex;
		align-items: center;
		line-height: 18px;

		.status-title {
			font-size: 14px;
			margin: 0;
			line-height: 20px;
		}

		&::before {
			position: relative;
			left: 0;
			padding-right: 8px;
		}

		.closer {
			font-size: 13px;
			position: relative;
			top: unset;
			right: unset;
			margin-left: 15px;
		}
	}
}

// FCT-940 - adding support for older versions

@font-family-icons: "s24-icons" !important;
// TODO: Update to use border-radius token when it becomes available
@status-message-box-border-radius: 4px;

.status-message-box {
	background: @white;
	border: 1px solid @gray400;
}

.status-message.status-variant {
	position: relative;
	padding: 24px 20px 24px 24px;
	line-height: 20px;

	display: grid;
	grid-template-columns: 32px auto 16px;

	border-radius: @status-message-box-border-radius;
	.status-message-box();

	&::after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		border-radius: calc(@status-message-box-border-radius - 1px) 0 0 calc(@status-message-box-border-radius - 1px);
		width: 7px;
	}

	@media @mediaQueryPalmOnly {
		padding: 16px 12px 16px 16px;
	}

	&::before {
		left: unset;
		position: static;
		font-size: 24px;
		font-family: @font-family-icons;
		display: inline;
		line-height: 24px;
		grid-column: 1;
		text-align: left;
		height: 24px;
		padding: 0;

		@media @mediaQueryPalmOnly {
			line-height: inherit;
		}
	}

	.status-title {
		font-size: 18px;
		line-height: 28px;
		font-weight: 700;
		display: inline-block;
		margin-bottom: 8px;
		grid-column: 2;

		@media @mediaQueryPalmOnly {
			line-height: 22px;
		}
	}

	.status-description {
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		grid-column: 2;
	}

	.closer {
		position: static;
		border: none;
		background: none;
		grid-column: 3;
		grid-row: 1;
		padding: 0;
		cursor: pointer;
		display: flex;

		&::before {
			content: unset; // override legacy styles
		}

		&:empty {
			&:extend(.s24-icons-s24_close_24 all);

			&::before {
				display: inline;
				font-family: @font-family-icons;
			}
		}

		&-icon {
			width: 16px;
			height: 16px;
			font-size: 16px;
		}
	}

	.status-action-button {
		font-size: 14px;
		font-weight: 400;
		margin-top: 8px;
		grid-column: 2;
	}

	&.status-confirm {
		&:extend(.s24-icons-s24_checkmark_circle_24 all);

		&::before {
			color: @green500;
		}

		&::after {
			background-color: @green500;
		}
	}

	&.status-info,
	&.status-warning {
		&:extend(.s24-icons-s24_info_circle_24 all);

		&::before {
			color: @blue500;
		}

		&::after {
			background-color: @blue500;
		}
	}

	&.status-error {
		&:extend(.s24-icons-s24_alert_triangle_24 all);

		&::before {
			color: @red500;
		}

		&::after {
			background-color: @red500;
		}
	}

	&.status-message--toast {
		padding: 16px 12px 16px 12px;
		border-left-width: 4px;
		box-shadow: 0px 4px 10px 2px #00000014;

		.status-message-box();

		.status-title {
			font-size: 18px;
			font-weight: 600;
			line-height: 25px;
		}

		&::before {
			line-height: 25px;
		}

		&::after {
			width: 4px;
		}
	}

	&.status-message--minimal {
		border: none;
		background: none;
		padding: 0;
		display: inline-flex;
		align-items: center;
		line-height: 18px;
		box-shadow: none;

		.status-title {
			font-size: 14px;
			margin: 0;
			font-weight: 400;
			padding-left: 0;
		}

		&::before {
			position: relative;
			left: 0;
			padding-right: 8px;
		}

		&::after {
			display: none;
		}

		.closer {
			font-size: 16px;
			position: relative;
			top: unset;
			right: unset;
			margin-left: 15px;
			line-height: 16px;
		}
	}

	&.status-message--promotion {
		padding: 24px;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
		border-radius: 4px;
		grid-template-columns: 0 auto 16px;

		.status-message-box();

		@media @mediaQueryPalmOnly {
			padding: 16px;
		}

		.promotion-icon {
			i,
			span {
				font-size: 24px;
			}
			svg {
				width: 24px;
				height: 24px;
			}
		}

		.status-title {
			font-size: 18px;
			font-weight: 700;
			line-height: 28px;
		}

		.status-description {
			font-size: 14px;
			line-height: 22px;
			font-weight: 400;
		}
	}
	&.status-message--promotion:has(> .promotion-icon) {
		grid-template-columns: 32px auto 16px;
	}
}
