@import "../../../less/_mixins/typography.less";
@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/general.less";

.common-styles(@iconSize) {
	border-radius: 50%;
	padding: 0;

	svg,
	i,
	span {
		width: @iconSize;
		height: @iconSize;
		font-size: @iconSize;
	}
}

.icon-button-rounded {
	&.icon-button-rounded-small {
		width: 28px;
		height: 28px;
		.common-styles(16px);
	}

	&.icon-button-rounded-medium {
		width: 40px;
		height: 40px;
		.common-styles(16px);
	}

	&.icon-button-rounded-large {
		width: 48px;
		height: 48px;
		.common-styles(24px);
	}
}
