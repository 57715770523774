@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/spacing.less";

@paginationHeight: 48px;

.carousel-slider {
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	display: block;
	position: relative;
	overflow: hidden;
	list-style: none;
	z-index: 1;
	touch-action: pan-y;

	&-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transform: translate3d(0px, 0, 0);
		transition-property: transform;
		transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
		box-sizing: content-box;
	}

	&-slide {
		width: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-button {
		display: none;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: calc(50% - (@paginationHeight / 2));
		transform: translateY(-50%);
		width: 48px;
		height: 48px;
		border-radius: 100px;
		background: var(--infographic-day-white, @white) !important;
		border: 1px solid @gray650;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
		z-index: 2;

		@media @mediaQueryDeskAndUp {
			display: flex;
		}

		&-prev {
			left: @lapAndUpSpacingS;
		}

		&-next {
			right: @lapAndUpSpacingS;
		}
	}

	&-pagination {
		width: 100%;
		height: @paginationHeight;
		position: static;
		display: flex;
		gap: @lapAndUpSpacingS;
		justify-content: center;
		align-items: center;
	}

	&-dot {
		box-sizing: border-box;
		display: inline-block;
		height: @lapAndUpSpacingS;
		width: @lapAndUpSpacingS;
		border-radius: 100%;
		background-color: @gray300;
		padding: 0;
		cursor: pointer;
		opacity: 1;

		&:focus-visible {
			border: 1px solid @gray1000;
		}

		&--active {
			background-color: @gray1000;
		}
	}

	&--without-dots {
		.carousel-slider-button {
			top: 50%;
		}
	}
}
